import { createSelector } from 'reselect';

const selectPaintCollections = createSelector(
  (state) => state.product,
  (product) => {
    return product.paintCollections;
  }
);

export default selectPaintCollections;
