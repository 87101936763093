import gql from 'graphql-tag';

export const CUSTOMER_RECENT_SAMPLE_ORDERS = gql`
  query CustomerRecentSampleOrders($token: String!) {
    customerRecentSampleOrders(token: $token) {
      id
      createdAt
      customerName
      products {
        id
        handle
        images {
          edges {
            node {
              originalSrc
              transformedSrc
            }
          }
        }
        tags
      }
    }
  }
`;
