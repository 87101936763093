import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { decodeQueryParams, StringParam } from 'serialize-query-params';
import queryString from 'query-string';
import { Box } from 'grommet';

import { TildeArrayParam } from '../../lib/customParams';
import { get, find, map, flatten, intersection } from '../../lib/nodash';
import Loading from '../../components/Loading';
import PageCalculator from '../../components/PageCalculator';
import Seo from '../../components/Seo';
import { getColorSlugFromProductHandle } from '../../lib/color';
import { CUSTOMER_RECENT_SAMPLE_ORDERS } from '../../queries/orderQueries';
import selectPaintCollections from '../../state/product/selectPaintCollections';
import deNodify from '../../lib/deNodify';

const OrderNowTemplate = ({ location, data }) => {
  const {
    allStrapiColor,
    storyblokEntry,
    allStrapiTagGroup,
    allPaintCollections,
  } = data;
  const tagGroups = deNodify(allStrapiTagGroup);
  const canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname;
  const colors = deNodify(allStrapiColor);
  const colorGroup = find({ slug: 'color' }, tagGroups);
  const featuredGroup = find({ slug: 'paint-collection-featured' }, tagGroups);
  const searchParams = queryString.parse(location.search);

  const decodedQuery = decodeQueryParams(
    {
      samples: TildeArrayParam,
      token: StringParam,
    },
    searchParams
  );

  const { samples, token } = decodedQuery;

  const { data: sampleOrdersData, loading } = useQuery(
    CUSTOMER_RECENT_SAMPLE_ORDERS,
    {
      variables: { token },
      skip: !token,
    }
  );

  const sampleOrders = get('customerRecentSampleOrders', sampleOrdersData);
  const sampledProductIds = map('id', flatten(map('products', sampleOrders)));

  const sorter = {
    name: 'Light to Dark',
    id: 'colorIndex',
    position: 1,
    sort: true,
  };
  const featuredFilters = [
    ...(get('tags', featuredGroup) || []).slice(0, 1),
    sorter,
    ...(get('tags', featuredGroup) || []).slice(1),
  ];

  const filterGroups = [
    {
      title: get('name', colorGroup),
      labelKey: 'name',
      valueKey: 'slug',
      filters: [...featuredFilters, ...(get('tags', colorGroup) || [])],
      separators: [(get('tags', featuredGroup) || []).length],
      open: true,
    },
  ];

  const sortedColors = colors;

  const allPaintCollectionsArray = deNodify(allPaintCollections);

  const paintCollections =
    samples.length && !decodedQuery.token
      ? allPaintCollectionsArray.filter(
          (c) =>
            map(getColorSlugFromProductHandle, samples).indexOf(c.handle) > -1
        )
      : sampledProductIds.length
      ? allPaintCollectionsArray
      : allPaintCollectionsArray;

  return (
    <>
      <Seo
        title={
          get('content.metafields.title', storyblokEntry) ||
          get('title', storyblokEntry) ||
          'Order Now'
        }
        description={get('content.metafields.description', storyblokEntry)}
        canonical={canonicalUrl}
      />
      {loading ? (
        <Box
          pad="large"
          justify="center"
          align="center"
          fill
          height={{ min: '30vh' }}
        >
          <Loading size="large" />
        </Box>
      ) : (
        <PageCalculator
          colors={sortedColors}
          filterGroups={filterGroups}
          sampleOrders={sampleOrders}
          paintCollections={paintCollections}
          hasSamples={samples.length > 0}
        />
      )}
    </>
  );
};

OrderNowTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default memo(OrderNowTemplate);

export const query = graphql`
  query OrderNowPageContent {
    allStrapiColor {
      edges {
        node {
          strapiId
          id
          name
          hex_value
          description
          short_description
          slug
          similar_colors {
            id
            slug
          }
          tags {
            name
            slug
            id
          }
        }
      }
    }
    allPaintCollections: allShopifyCollection(
      filter: {
        metafields: {
          elemMatch: {
            key: { eq: "type" }
            namespace: { eq: "collection" }
            value: { eq: "Paint" }
          }
        }
      }
      sort: {
        order: [ASC, ASC]
        fields: [
          products___variants___product___handle
          products___variants___id
        ]
      }
    ) {
      edges {
        node {
          id
          shopifyId
          title
          handle
          metafields {
            type
            key
            value
            description
            namespace
          }
          products {
            id
            shopifyId
            title
            productType
            variants {
              id
              shopifyId
              price
              title
              availableForSale
              inventoryPolicy
            }
          }
        }
      }
    }
    storyblokEntry(slug: { eq: "calculator" }) {
      id
      name
      full_slug
      content
      field_title_string
      internalId
    }
    allStrapiTagGroup(
      filter: { slug: { in: ["paint-collection-featured", "color"] } }
    ) {
      edges {
        node {
          slug
          name
          id
          tags {
            name
            id
            slug
          }
          tag_groups {
            id
            name
            slug
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
